/* eslint-disable jsx-a11y/anchor-is-valid */
import { MenuOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Switch } from "../../../../layouts/MainLayout/Sidebar";
import { AppRoutesName } from "../../../../router/AppRoutesName";
import s from "./Header.module.scss";

type Props = {
  hideMenuLinks?: boolean;
};

export const Header: FC<Props> = ({ hideMenuLinks }) => {
  const menuLinks = [
    {
      label: "Главная",
      link: "#",
      key: "1",
    },
    {
      label: "О нас",
      link: "#aboutUs",
      key: "2",
    },
    {
      label: "Кейсы",
      link: "#cases",
      key: "3",
    },
    {
      label: "Наши продукты",
      link: "#results",
      key: "4",
    },
    {
      label: "Статьи",
      link: "#",
      key: "5",
    },
  ];
  const location = useLocation();

  const getLinkInfo = () => {
    if (location.pathname === "/login") {
      return { label: "Вернуться на сайт", link: "/landing" };
    }
    if (location.pathname === "/landing") {
      return { label: "Вернуться на платформу", link: "/login" };
    }
    return null;
  };
  const linkInfo = getLinkInfo();
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const { i18n } = useTranslation();

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  const [toggled, setToggled] = useState<boolean>(true);
  const handleToggleLanguage = () => {
    setToggled(!toggled);
    // const newLanguage = isToggled ? "ru" : "kz";
    // i18n.changeLanguage(newLanguage);
    // localStorage.setItem("language", newLanguage);
  };
  return (
    <div className={s.wrapper}>
      <a href={AppRoutesName.landingPage}>
        <img width={91} src="logo_satoo.svg" alt="logo" />
      </a>
      {!hideMenuLinks ? (
        <div className={s.menu}>
          {menuLinks.map((item, index) => (
            <div key={index}>
              <a className={s.menuItemLink} href={item.link}>
                {item.label}
              </a>
            </div>
          ))}
          <div className={s.menuItem}>
            <a className={s.menuItemLink} href="mailto:senimsatu@gmail.com">
              {" "}
              Хочешь к нам в команду?
            </a>
          </div>
        </div>
      ) : null}
      {linkInfo && (
        <a href={linkInfo.link} className={s.goToPlatform}>
          {linkInfo.label}
        </a>
      )}
      <div className={s.logo}>
        <div className={s.logo}>
          <Switch onClick={handleToggleLanguage} toggled={toggled} label={toggled ? "ru" : "kz"} />
          <div
            onClick={toggleMenu}
            style={{ marginLeft: "20px" }}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                toggleMenu();
              }
            }}
            aria-label="Edit item"
          >
            <MenuOutlined style={{ fontSize: "24px" }} />
          </div>
        </div>
      </div>

      {menuVisible && (
        <Modal
          open={menuVisible}
          onCancel={toggleMenu}
          footer={null}
          width={300}
          style={{ top: 20, right: 20, position: "absolute" }}
        >
          <div className={s.burger}>
            {menuLinks.map((item, index) => (
              <div className={s.menuItem} key={index}>
                <a className={s.menuItemLink} href={item.link} onClick={toggleMenu}>
                  {item.label}
                </a>
              </div>
            ))}
          </div>
        </Modal>
      )}
    </div>
  );
};
