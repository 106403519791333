import { Divider, Layout, Menu, MenuProps } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { RoleEnum } from "../../../api";
import accountsApi from "../../../api/Accounts/AccountsApi";
import { getCurrentUserGroups, getCurrentUserName, getUserRole } from "../../../authHelper";
// import { LocalRole } from "../../../LocalRole";
import { AdminSidebarRoutes } from "../../../router/sidebar/AdminSidebarRoutes";
import { ManagerSidebarRoutes } from "../../../router/sidebar/ManagerSidebarRoutes";
import { OwnerSidebarRoutes } from "../../../router/sidebar/OwnerSidebarRoutes";
import { SideBarItem } from "../../../router/sidebar/sidebar.type";
import { MenuItems } from "./components/MenuItems";
import { Switch } from "./components/Switch";
import s from "./Sidebar.module.scss";

const { Sider } = Layout;

type Props = {
  userInfo: {
    email: string;
  };
};

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  onClick?: () => void,
  danger?: boolean,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    onClick,
    danger,
  } as MenuItem;
}

// const role: string = LocalRole;
const Sidebar: FC<Props> = ({ userInfo }) => {
  const [items, setItems] = useState<MenuItem[]>([]);
  const [name, setName] = useState<string>("");
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();
  const privilegedMenuItems: MenuItem[] = [];
  const [role, setRole] = useState<string | null>(() => getUserRole());

  useEffect(() => {
    const fetchedRole = getUserRole();
    console.log("Fetched role:", fetchedRole);
    setRole(fetchedRole);
  }, []);
  const getMenuItemsByRole = (role: string | null): SideBarItem[] => {
    switch (role) {
      case RoleEnum.OWNER:
        return OwnerSidebarRoutes;
      case RoleEnum.ROP:
        return OwnerSidebarRoutes;
      case RoleEnum.MANAGER:
        return ManagerSidebarRoutes;
      case RoleEnum.ADMINISTRATOR:
        return AdminSidebarRoutes;
      default:
        return [];
    }
  };

  const systemMenuItems = (): MenuItem[] => {
    const routes: SideBarItem[] = getMenuItemsByRole(role);
    return routes.map((route) =>
      getItem(route.label, route.key, route.icon, () => {
        navigate(route.link);
      }),
    );
  };
  useEffect(() => {
    const getGroups = () => getCurrentUserGroups();
    const userGroups = getGroups();
    setItems([...systemMenuItems(), ...(userGroups?.length > 0 ? privilegedMenuItems : [])]);
    const name = getCurrentUserName();
    setName(name);
  }, []);

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  // const handleChangeLanguage = (language: string) => {
  //   i18n.changeLanguage(language);
  //   localStorage.setItem("language", language);
  //   window.location.reload();
  // };

  const handleLogout = () => {
    accountsApi.logout();
    window.location.reload();
  };

  // const contentAlerts = (
  //   <Menu className={s.menu}>
  //     <Menu.Item onClick={() => navigate("/my-approvals")}>Approval is needed</Menu.Item>
  //   </Menu>
  // );

  // const contentLanguages = (
  //   <Menu className={s.menu}>
  //     <Menu.Item onClick={() => handleChangeLanguage("en")}>English</Menu.Item>
  //     <Menu.Item onClick={() => handleChangeLanguage("kk")}>Kazakh</Menu.Item>
  //     <Menu.Item onClick={() => handleChangeLanguage("ru")}>Russian</Menu.Item>
  //   </Menu>
  // );
  const [toggled, setToggled] = useState<boolean>(true);
  const handleToggleLanguage = () => {
    setToggled(!toggled);
    // const newLanguage = isToggled ? "ru" : "kz";
    // i18n.changeLanguage(newLanguage);
    // localStorage.setItem("language", newLanguage);
  };

  return (
    <Sider className={s.sidebar} theme="light">
      <div className={s.content}>
        <div className={s.main}>
          <div className={s.logo}>
            <NavLink to="/">
              <img src="/logo_satoo.svg" width={90} alt="Logo" />
            </NavLink>
            <Switch
              onClick={handleToggleLanguage}
              toggled={toggled}
              label={toggled ? "ru" : "kz"}
            />
          </div>
          <div className={s.user}>
            <div className={s.profile}>
              <img
                src={`https://ui-avatars.com/api/?name=${name}&color=FFD966&background=FFF1C6`}
                alt="profile"
              />
            </div>
            <div className={s.info}>
              <div className={s.name}>{name}</div>
              <div className={s.email}>{userInfo?.email}</div>
            </div>
          </div>
          <Divider style={{ margin: "0" }} />
          <Menu mode="inline" className={s.menu} items={items} selectedKeys={[location.pathname]} />
        </div>
        <MenuItems className={s.menu} style={{ marginTop: 0 }} handleLogout={handleLogout} />
      </div>
    </Sider>
  );
};

export { Sidebar };
