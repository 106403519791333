/* eslint-disable jsx-a11y/anchor-is-valid */
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, List, message, Modal, Tabs, theme } from "antd";
import { useEffect, useState } from "react";
import { ApiService } from "../../../../api";
import useRegisterBlockStore from "../../../../store/registerBlockStore";
import s from "../../css/Company/CompanyCreate.module.scss";
import { CreateCompanyProps } from "../../interfaces/Company";
import { FirstScript } from "./FirstScript";
import { SecondScript } from "./SecondScript";
import { ThirdScript } from "./ThirdScript";
// import { RegisterBlock } from "../../../../api/models/RegisterBlock";

const { TabPane } = Tabs;

interface ManagerSetting {
  id: string;
  name: string;
}

export const NewCreateCompanyThirdStep = ({
  company_id,
  fetch,
  setFetch,
  company,
  setCurrent,
  current,
  stepBack,
}: CreateCompanyProps) => {
  const [form] = Form.useForm();
  const { token } = theme.useToken();
  const [managerSettings, setManagerSettings] = useState<ManagerSetting[]>([]);
  const [managerSettingId, setManagerSettingId] = useState<string>("");
  const [activeKeys, setActiveKeys] = useState<string[]>([]);
  const registerBlocks = useRegisterBlockStore((state) => state.registerBlocks);
  // Скрипты
  const [firstScript, setFirstScript] = useState(false);
  const [secondScript, setSecondScript] = useState(false);
  const [thirdScript, setThirdScript] = useState(false);

  useEffect(() => {
    if (company?.company_manager_settings) {
      setManagerSettings(company.company_manager_settings);
    }
  }, [company]);

  const handleAddSettings = async (values: { name: string }) => {
    try {
      const response = await ApiService.apiAccountsManagerSettingCreate({
        company_id: company_id!,
        name: values.name,
      });
      setManagerSettings((prevSettings) => [
        ...prevSettings,
        { id: response.manager_settings_id, name: values.name },
      ]);
      form.resetFields();
      message.success("Роль менеджера успешно добавлена");
    } catch (e) {
      console.error(e);
      message.error("Не удалось добавить роль менеджера");
    }
  };

  const handleNextStep = async () => {
    if (!company_id || !company?.step_register) {
      message.error("Информация о компании неполная");
      return;
    }

    try {
      await ApiService.apiAccountsCompanyPartialUpdate(company_id, {
        step_register: company.step_register + 1,
      });
      setFetch(fetch + 1);
      setCurrent(current + 1);
    } catch (e) {
      console.error(e);
      message.error("Не удалось перейти к следующему шагу");
    }
  };

  const saveSettings = () => {
    if (!managerSettingId && !firstScript && !secondScript && !thirdScript) {
      return false;
    }
    if (managerSettingId && firstScript && secondScript && thirdScript) {
      message.success("Скрипты успешно созданы");

      return false;
    }
    if (managerSettingId && !firstScript && !secondScript && !thirdScript) {
      return true;
    }
  };

  const handleAddScript = (id: string) => {
    setManagerSettingId(id);
  };

  const handleDeleteSettings = async (id: string) => {
    Modal.confirm({
      title: "Удалить роль менеджера?",
      content: "Вы уверены, что хотите удалить эту роль?",
      okText: "Да",
      cancelText: "Нет",
      onOk: async () => {
        try {
          await ApiService.apiAccountsManagerSettingDestroy(id);
          message.success("Роль менеджера успешно удалена");
          setFetch(fetch + 1);
        } catch (e) {
          console.error(e);
          message.error("Не удалось удалить роль менеджера");
        }
      },
    });
  };

  const checkSettingsExist = (id: string) => {
    const setting = registerBlocks.find((item) => item.manager_setting === id);
    if (setting || (managerSettingId && firstScript && secondScript && thirdScript)) {
      return "Скрипты созданы";
    }
    return "Создать скрипт";
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", marginTop: "30px" }}>
      <Form form={form} layout="horizontal" onFinish={handleAddSettings}>
        <div className={s.flex}>
          <Col span={12}>
            <div className={s.flex}>
              <Form.Item
                style={{ width: "100%" }}
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Пожалуйста, напишите название роли менеджера",
                  },
                ]}
              >
                <Input
                  placeholder="Название роли менеджера.."
                  style={{ borderRadius: "57px", width: "100%" }}
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" style={{ borderRadius: "47px" }}>
                  Добавить
                </Button>
              </Form.Item>
            </div>
          </Col>
          <Col span={10} offset={1}>
            <List
              bordered
              dataSource={managerSettings}
              renderItem={(item) => {
                return (
                  <List.Item
                    actions={[
                      <DeleteOutlined
                        key="delete"
                        onClick={() => handleDeleteSettings(item.id)}
                        style={{ color: "red" }}
                      />,
                      <Button type="link" onClick={() => handleAddScript(item.id)} key="add-script">
                        {checkSettingsExist(item.id)}
                      </Button>,
                    ]}
                  >
                    {item.name}
                  </List.Item>
                );
              }}
              style={{
                background: token.colorBgContainer,
                borderColor: "transparent",
                borderRadius: "8px",
                height: "auto",
                flexWrap: "nowrap",
              }}
            />
          </Col>
        </div>
      </Form>

      {managerSettingId && !(firstScript && secondScript && thirdScript) && (
        <Tabs
          activeKey={activeKeys[0] || "1"}
          onChange={(key) => setActiveKeys([key])}
          style={{ marginTop: "2em" }}
        >
          <TabPane tab="Соблюдение скрипта" key="1">
            <FirstScript
              setFirstScript={setFirstScript}
              managerSettingId={managerSettingId}
              company_id={company_id || ""}
            />
          </TabPane>
          <TabPane tab="Соблюдение техник продаж" key="2">
            <SecondScript
              setSecondScript={setSecondScript}
              managerSettingId={managerSettingId}
              company_id={company_id || ""}
            />
          </TabPane>
          <TabPane tab="Клиент" key="3">
            <ThirdScript
              setThirdScript={setThirdScript}
              managerSettingId={managerSettingId}
              company_id={company_id || ""}
            />
          </TabPane>
        </Tabs>
      )}

      <Col span={24} style={{ marginTop: "3em" }}>
        <Button style={{ marginRight: "1em", borderRadius: "47px" }} onClick={stepBack}>
          Назад
        </Button>
        <Button
          type="primary"
          onClick={handleNextStep}
          style={{ borderRadius: "47px" }}
          disabled={saveSettings()}
        >
          Далее
        </Button>
      </Col>
    </div>
  );
};
