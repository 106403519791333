/* eslint-disable jsx-a11y/control-has-associated-label */
import { AppRoutesName } from "../../../../router/AppRoutesName";
import s from "./Footer.module.scss";
import LogoYellow from "./icons/LogoYellow";

export const Footer = () => {
  return (
    <div className={s.wrapper}>
      <div className={s.main}>
        <div className={s.item}>
          <div>
            <a className={s.logo} href={AppRoutesName.landingPage}>
              <LogoYellow />
            </a>
            <div className={s.contacts}>
              <span>телефон</span>
              <a href="tel:+77057788985">+ 7 (705) 778 89 85</a>
            </div>
            <div className={s.contacts}>
              <span>эл.почта</span>
              <a href="mailto:senimsatu@gmail.com">senimsatu@gmail.com</a>
            </div>
            <div className={s.joinOurTeam}>
              <h4>Хочешь к нам в команду?</h4>
              <p>
                Пиши нам на почту! <br />
                Мы с радостью рассмотрим <br /> твою кандидатуру.
              </p>
            </div>
          </div>
          <p className={s.copyRight}>© Satoo, 2024</p>
        </div>
        <div className={s.item}>
          <div>
            <h4 className={s.title}>
              Получите доступ к уникальным материалам <br /> в нашем Telegram-боте
            </h4>
            <ul className={s.list}>
              <li>Чек-листы каждый день</li>
              <li>Анонсы бесплатных вебинаров</li>
              <li>Лучшие инструменты для увеличения продаж</li>
              <li>Видео о продажах и роста дохода</li>
            </ul>
            <div className={s.owner}>
              <img src="images/landingPage/SultanBekbosin.png" alt="SultanBekbosin" />
              <div className={s.info}>
                <p className={s.name}>Султан Бекбосын</p>
                <p className={s.position}>Должность</p>
              </div>
            </div>
            <div className={s.socials}>
              <a className={s.social} href="#/">
                <img src="images/landingPage/telegram1.png" alt="" />
              </a>
              <a className={s.social} href="#/">
                <img src="images/landingPage/whatsapp1.png" alt="" />
              </a>
              <a className={s.social} href="#/">
                <img src="images/landingPage/instagram1.png" alt="" />
              </a>
              <a href="#/" className={s.goTop}>
                Наверх
              </a>
            </div>
          </div>
          <a className={s.publicOffer} href="#/">
            Политика конфиденциальности
          </a>
        </div>
        <div>
          <h4 className={s.subtitle}>Навигация</h4>
          <div className={s.menu}>
            <a href="/">Главная</a>
            <a href="#aboutUs">О нас</a>
            <a href="#cases">Кейсы</a>
            <a href="#/">Наши продукты</a>
            <a href="#/">Статьи</a>
          </div>
        </div>
        <div>
          <h4 className={s.subtitle}>Адрес</h4>
          <a className={s.address} href="#/">
            г. Алматы, <br />
            ул. Жандосова, 198
          </a>
        </div>
      </div>
    </div>
  );
};
