/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, InputNumber, Modal, Space, Table, message } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useState } from "react";
import { ApiService } from "../../../../api";

// Определение интерфейсов
interface Module {
  id: number;
  name: string;
  scenario: string;
  weight: number;
  description: string;
}

interface Block {
  id: number;
  name: string;
  modules: Module[];
}

interface ComplianceScript {
  key: string;
  compliance: string;
  blocks: Block[];
}

interface FlattenedRow {
  key: string;
  compliance: string;
  blockName?: string;
  blockRowSpan?: number;
  moduleName?: string;
  scenario?: string;
  weight?: number;
  description?: string;
  isAddBlockRow?: boolean; // Флаг для строки с формой добавления блока
  isAddModuleRow?: boolean; // Флаг для строки с кнопкой добавления модуля
}

interface FirstScriptProps {
  setFirstScript: (value: boolean) => void;
  managerSettingId: string;
  company_id: string;
}

export const FirstScript = ({ setFirstScript, managerSettingId, company_id }: FirstScriptProps) => {
  const [data, setData] = useState<ComplianceScript[]>([
    {
      key: "1",
      compliance: "Соблюдение скрипта",
      blocks: [],
    },
  ]);

  // Состояния для модальных окон блоков
  const [isBlockModalVisible, setIsBlockModalVisible] = useState(false);
  const [currentBlock, setCurrentBlock] = useState<Block | null>(null);
  const [blockForm] = Form.useForm();

  // Состояния для модальных окон модулей
  const [isModuleModalVisible, setIsModuleModalVisible] = useState(false);
  const [currentModule, setCurrentModule] = useState<{
    blockId: number;
    module: Module | null;
  } | null>(null);
  const [moduleForm] = Form.useForm();

  // Функции для управления блоками
  const addBlock = async (blockName: string) => {
    try {
      const response = await ApiService.apiMainCreateRegisterBlockList({
        name: blockName,
        block_type: data[0].compliance,
        default: true,
        manager_setting: managerSettingId,
        company: company_id,
      });

      if (response) {
        const newBlock: Block = {
          id: response.id, // Ожидаем, что сервер возвращает число
          name: blockName,
          modules: [],
        };
        setData((prevData) =>
          prevData.map((script) =>
            script.key === "1" ? { ...script, blocks: [...script.blocks, newBlock] } : script,
          ),
        );
        message.success("Блок успешно добавлен");
      }
    } catch (error) {
      console.error("Ошибка при добавлении блока:", error);
      message.error("Не удалось добавить блок");
    }
  };

  const editBlock = async (blockId: number, newName: string) => {
    try {
      const response = await ApiService.apiMainEditRegisterBlockList(company_id, {
        id: blockId,
        name: newName,
        block_type: data[0].compliance,
        default: true,
        manager_setting: managerSettingId,
        company: company_id,
      });
      if (response) {
        setData((prevData) =>
          prevData.map((script) =>
            script.key === "1"
              ? {
                  ...script,
                  blocks: script.blocks.map((block) =>
                    block.id === blockId ? { ...block, name: newName } : block,
                  ),
                }
              : script,
          ),
        );
        message.success("Блок успешно обновлён");
      }
    } catch (error) {
      console.error("Ошибка при обновлении блока:", error);
      message.error("Не удалось обновить блок. Произошла ошибка.");
    }
  };

  const deleteBlock = async (blockId: number) => {
    try {
      // Найти блок с данным blockId
      const block = data[0].blocks.find((b) => b.id === blockId);
      if (block) {
        if (block.modules.length > 0) {
          // Удалить все модули блока
          await Promise.all(
            block.modules.map((module) => ApiService.apiMainDeleteCriteriaList(module.id)),
          );
          message.success("Все модули блока успешно удалены");
        }
        // Удалить сам блок
        await ApiService.apiMainDeleteRegisterBlockList(blockId);
        setData((prevData) =>
          prevData.map((script) =>
            script.key === "1"
              ? {
                  ...script,
                  blocks: script.blocks.filter((b) => b.id !== blockId),
                }
              : script,
          ),
        );
        message.success("Блок успешно удалён");
      } else {
        message.error("Блок не найден");
      }
    } catch (error) {
      console.error("Ошибка при удалении блока:", error);
      message.error("Не удалось удалить блок. Произошла ошибка.");
    }
  };

  // Функция для вычисления общей суммы весов всех модулей
  const getTotalWeight = (): number => {
    let total = 0;
    data[0].blocks.forEach((block) => {
      block.modules.forEach((module) => {
        total += module.weight;
      });
    });
    return total;
  };

  // Функция для вычисления доступного веса для нового/редактируемого модуля
  const getAvailableWeight = (): number => {
    if (currentModule && currentModule.module) {
      // При редактировании, вычитаем текущий вес модуля из общей суммы
      return 100 - (getTotalWeight() - currentModule.module.weight);
    }
    // При добавлении нового модуля
    return 100 - getTotalWeight();
  };

  // Функции для управления модулями
  const addModule = async (blockId: number, module: Module) => {
    try {
      const response = await ApiService.apiMainCreateCriteriaList({
        name: module.name,
        scenario: module.scenario,
        description: module.description, // Передаём как строку
        weight: module.weight,
        block: blockId,
      });

      if (response && typeof response.id === "number") {
        const newModule: Module = {
          id: response.id, // Используем ID от сервера
          name: module.name,
          scenario: module.scenario,
          weight: module.weight,
          description: module.description,
        };

        setData((prevData) =>
          prevData.map((script) =>
            script.key === "1"
              ? {
                  ...script,
                  blocks: script.blocks.map((block) =>
                    block.id === blockId
                      ? { ...block, modules: [...block.modules, newModule] }
                      : block,
                  ),
                }
              : script,
          ),
        );

        setFirstScript(true);
        message.success("Модуль успешно добавлен");
      } else {
        message.error("Ошибка при добавлении модуля");
      }
    } catch (error) {
      console.error("Ошибка при добавлении модуля:", error);
      message.error("Не удалось добавить модуль");
    }
  };

  const editModule = async (blockId: number, moduleId: number, updatedModule: Module) => {
    try {
      const response = await ApiService.apiMainEditCriteriaList(moduleId, {
        name: updatedModule.name,
        scenario: updatedModule.scenario,
        description: updatedModule.description, // Передаём как строку
        weight: updatedModule.weight,
        block: blockId,
      });

      if (response) {
        setData((prevData) =>
          prevData.map((script) =>
            script.key === "1"
              ? {
                  ...script,
                  blocks: script.blocks.map((block) =>
                    block.id === blockId
                      ? {
                          ...block,
                          modules: block.modules.map((module) =>
                            module.id === moduleId ? { ...module, ...updatedModule } : module,
                          ),
                        }
                      : block,
                  ),
                }
              : script,
          ),
        );
        message.success("Модуль успешно обновлён");
      } else {
        message.error("Ошибка при обновлении модуля");
      }
    } catch (error) {
      console.error("Ошибка при обновлении модуля:", error);
      message.error("Не удалось обновить модуль");
    }
  };

  const deleteModule = async (blockId: number, moduleId: number) => {
    console.log(blockId, moduleId);
    try {
      await ApiService.apiMainDeleteCriteriaList(moduleId);
      setData((prevData) =>
        prevData.map((script) =>
          script.key === "1"
            ? {
                ...script,
                blocks: script.blocks.map((block) =>
                  block.id === blockId
                    ? { ...block, modules: block.modules.filter((m) => m.id !== moduleId) }
                    : block,
                ),
              }
            : script,
        ),
      );
      message.success("Модуль успешно удалён");
    } catch (error) {
      console.error("Ошибка при удалении модуля:", error);
      message.error("Не удалось удалить модуль. Произошла ошибка.");
    }
  };

  // Обработчики нажатия "OK" в модальных окнах
  const handleBlockOk = () => {
    blockForm
      .validateFields()
      .then((values) => {
        if (currentBlock) {
          editBlock(currentBlock.id, values.name);
        } else {
          addBlock(values.name);
        }
        setIsBlockModalVisible(false);
        blockForm.resetFields();
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleModuleOk = () => {
    moduleForm
      .validateFields()
      .then((values) => {
        const availableWeight = getAvailableWeight();
        const inputWeight = Number(values.weight);

        if (inputWeight > availableWeight) {
          message.error(
            `Суммарный вес всех модулей не может превышать 100. Доступный вес для этого модуля: ${availableWeight}.`,
          );
          return;
        }

        if (currentModule) {
          const { blockId, module } = currentModule;
          const newModule: Module = {
            id: module ? module.id : 0, // Для добавления оставляем id от API
            name: values.name,
            scenario: values.scenario,
            weight: inputWeight,
            description: values.description, // Передаём как строку
          };
          if (module) {
            // Редактирование существующего модуля
            editModule(blockId, module.id, { ...module, ...newModule });
          } else {
            // Добавление нового модуля
            addModule(blockId, newModule);
          }
        }
        setIsModuleModalVisible(false);
        moduleForm.resetFields();
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  // Функция для преобразования данных в плоский массив для таблицы
  const getFlattenedData = (): FlattenedRow[] => {
    const flattened: FlattenedRow[] = [];
    data[0].blocks.forEach((block) => {
      if (block.modules.length === 0) {
        // Если у блока нет модулей, добавляем строку с кнопкой "Добавить модуль"
        flattened.push({
          key: block.id.toString(),
          compliance: "", // Не повторяем "Соблюдение скрипта"
          blockName: block.name,
          blockRowSpan: 1,
          moduleName: "",
          scenario: "",
          weight: 0,
          description: "",
          isAddModuleRow: true, // Флаг для строки с кнопкой добавления модуля
        });
      } else {
        // Для блока с модулями
        block.modules.forEach((module, index) => {
          flattened.push({
            key: `${block.id}-${module.id}`,
            compliance: index === 0 ? data[0].compliance : "",
            blockName: index === 0 ? block.name : undefined,
            blockRowSpan: index === 0 ? block.modules.length + 1 : 0, // +1 для строки "Добавить модуль"
            moduleName: module.name,
            scenario: module.scenario,
            weight: module.weight,
            description: module.description,
          });
        });
        // Добавляем строку с кнопкой "Добавить модуль"
        flattened.push({
          key: `${block.id}-add`,
          compliance: "",
          blockName: undefined,
          blockRowSpan: 0,
          moduleName: "",
          scenario: "",
          weight: 0,
          description: "",
          isAddModuleRow: true, // Флаг для строки с кнопкой добавления модуля
        });
      }
    });

    // Добавляем строку с формой для добавления нового блока
    flattened.push({
      key: "add_block",
      compliance: "", // Эта строка будет покрыта первой ячейкой "Соблюдение скрипта"
      isAddBlockRow: true, // Флаг для идентификации строки
    });
    return flattened;
  };

  // Состояния для инпута нового блока
  const [newBlockName, setNewBlockName] = useState("");

  const handleAddBlockFromFooter = () => {
    if (newBlockName.trim() === "") {
      message.error("Название блока не может быть пустым");
      return;
    }
    addBlock(newBlockName.trim());
    setNewBlockName("");
  };

  const flattenedData = getFlattenedData();

  // Определение общего количества строк для "Соблюдение"
  const totalRows = flattenedData.length;

  // Определение колонок таблицы
  const columns: ColumnsType<FlattenedRow> = [
    {
      title: "Соблюдение",
      dataIndex: "compliance",
      key: "compliance",
      fixed: "left",
      width: 200,
      render: (text, row, index) => {
        // Группируем по первому блоку, оставляя "Соблюдение" как единый столбец
        if (index === 0) {
          return {
            children: data[0].compliance,
            props: {
              rowSpan: totalRows,
            },
          };
        }
        return {
          children: null,
          props: {
            rowSpan: 0,
          },
        };
      },
    },
    {
      title: "Блоки",
      dataIndex: "blockName",
      key: "blockName",
      width: 300,
      render: (text, row) => {
        if (row.isAddBlockRow) {
          return (
            <Form layout="inline" onFinish={handleAddBlockFromFooter} style={{ width: "100%" }}>
              <Form.Item style={{ flex: 1 }}>
                <Input
                  placeholder="Новый блок"
                  value={newBlockName}
                  onChange={(e) => setNewBlockName(e.target.value)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" icon={<PlusOutlined />}>
                  Сохранить блок
                </Button>
              </Form.Item>
            </Form>
          );
        }

        if (text) {
          // Извлекаем blockId из row.key
          const blockId = Number(row.key.split("-")[0]);

          return {
            children: (
              <Space>
                <span>{text}</span>
                <Button
                  icon={<EditOutlined />}
                  size="small"
                  onClick={() => {
                    const block = data[0].blocks.find((b) => b.id === blockId);
                    if (block) {
                      setCurrentBlock(block);
                      setIsBlockModalVisible(true);
                      blockForm.setFieldsValue({ name: block.name });
                    }
                  }}
                />
                <Button
                  icon={<DeleteOutlined />}
                  size="small"
                  danger
                  onClick={() => {
                    const blockIdToDelete = Number(row.key.split("-")[0]);
                    if (!Number.isNaN(blockIdToDelete)) {
                      Modal.confirm({
                        title: "Удаление блока",
                        content: "Вы уверены, что хотите удалить этот блок и все связанные модули?",
                        okText: "Да",
                        okType: "danger",
                        cancelText: "Нет",
                        onOk: () => deleteBlock(blockIdToDelete),
                      });
                    } else {
                      message.error("Некорректный ID блока");
                    }
                  }}
                />
              </Space>
            ),
            props: {
              rowSpan: row.blockRowSpan,
            },
          };
        }
        return {
          children: null,
          props: {
            rowSpan: 0,
          },
        };
      },
    },
    {
      title: "Модули",
      dataIndex: "moduleName",
      key: "moduleName",
      width: 300,
      render: (text, row) => {
        if (row.isAddBlockRow) {
          return null; // Не отображаем в строке с формой
        }

        if (row.isAddModuleRow) {
          // Строка с кнопкой "Добавить модуль"
          return (
            <Button
              type="dashed"
              onClick={() => {
                const blockId = Number(row.key.split("-")[0]); // Извлекаем blockId из ключа
                setCurrentModule({ blockId, module: null });
                setIsModuleModalVisible(true);
                moduleForm.resetFields();
              }}
              icon={<PlusOutlined />}
              size="small"
              style={{ marginTop: 8 }}
            >
              Добавить модуль
            </Button>
          );
        }

        return (
          <Space>
            {text}
            <Button
              icon={<EditOutlined />}
              size="small"
              onClick={() => {
                const [blockIdStr, moduleIdStr] = row.key.split("-");
                const blockId = Number(blockIdStr);
                const moduleId = Number(moduleIdStr);
                const block = data[0].blocks.find((b) => b.id === blockId);
                const module = block?.modules.find((m) => m.id === moduleId) || null;
                setCurrentModule({ blockId, module });
                setIsModuleModalVisible(true);
                if (module) {
                  moduleForm.setFieldsValue({
                    name: module.name,
                    scenario: module.scenario,
                    weight: module.weight,
                    description: module.description,
                  });
                } else {
                  moduleForm.resetFields();
                }
              }}
            />
            <Button
              icon={<DeleteOutlined />}
              size="small"
              danger
              onClick={() => {
                const [blockIdStr, moduleIdStr] = row.key.split("-");
                const blockId = Number(blockIdStr);
                const moduleId = Number(moduleIdStr);
                deleteModule(blockId, moduleId);
              }}
            />
          </Space>
        );
      },
    },
    {
      title: "Сценарий",
      dataIndex: "scenario",
      key: "scenario",
      width: 200,
      render: (text, row) => {
        if (row.isAddBlockRow || row.isAddModuleRow) {
          return null;
        }
        return text;
      },
    },
    {
      title: "Вес модуля",
      dataIndex: "weight",
      key: "weight",
      width: 100,
      render: (text, row) => {
        if (row.isAddBlockRow || row.isAddModuleRow) {
          return null;
        }
        return text;
      },
    },
    {
      title: "Критерии оценки",
      dataIndex: "description",
      key: "description",
      width: 300,
      render: (text, row) => {
        if (row.isAddBlockRow || row.isAddModuleRow) {
          return null;
        }
        return text;
      },
    },
  ];

  return (
    <div style={{ marginTop: "30px", height: "80%", overflow: "auto" }}>
      <Table
        columns={columns}
        dataSource={flattenedData}
        pagination={false}
        bordered
        rowKey="key"
        scroll={{ x: 1500, y: 500 }}
      />

      {/* Модальное окно для добавления/редактирования блока */}
      <Modal
        title={currentBlock ? "Редактировать блок" : "Добавить блок"}
        open={isBlockModalVisible}
        onCancel={() => setIsBlockModalVisible(false)}
        onOk={handleBlockOk}
        destroyOnClose
      >
        <Form form={blockForm} layout="vertical" name="blockForm">
          <Form.Item
            name="name"
            label="Название блока"
            rules={[{ required: true, message: "Пожалуйста, введите название блока." }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      {/* Модальное окно для добавления/редактирования модуля */}
      <Modal
        title={currentModule && currentModule.module ? "Редактировать модуль" : "Добавить модуль"}
        open={isModuleModalVisible}
        onCancel={() => setIsModuleModalVisible(false)}
        onOk={handleModuleOk}
        destroyOnClose
        okButtonProps={{ disabled: getAvailableWeight() <= 0 && !currentModule?.module }}
      >
        <Form form={moduleForm} layout="vertical" name="moduleForm">
          <Form.Item
            name="name"
            label="Название модуля"
            rules={[{ required: true, message: "Пожалуйста, введите название модуля." }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="scenario"
            label="Сценарий"
            rules={[{ required: true, message: "Пожалуйста, введите сценарий." }]}
          >
            <Input.TextArea placeholder="Введите сценарий" autoSize={{ minRows: 3, maxRows: 8 }} />
          </Form.Item>
          <Form.Item
            name="weight"
            label="Вес модуля"
            rules={[
              { required: true, message: "Пожалуйста, введите вес модуля." },
              {
                type: "number",
                min: 0,
                max: 100,
                message: "Вес модуля должен быть между 0 и 100",
                transform: (value) => {
                  // Преобразуем строку в число для корректной валидации
                  return Number(value);
                },
              },
            ]}
          >
            <InputNumber
              min={0}
              style={{ width: "100%" }}
              placeholder={`Максимум ${getAvailableWeight()}`}
            />
          </Form.Item>
          <Form.Item
            name="description"
            label="Критерии оценки"
            rules={[{ required: true, message: "Пожалуйста, введите хотя бы один критерий." }]}
          >
            <Input.TextArea
              autoSize={{ minRows: 3, maxRows: 8 }}
              placeholder="Введите критерии через запятую"
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
