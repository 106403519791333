/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { AddManagersRequest } from "../models/AddManagersRequest";
import type { Block } from "../models/Block";
import type { BlockList } from "../models/BlockList";
import type { BlockRequest } from "../models/BlockRequest";
import type { ChosePipline } from "../models/ChosePipline";
import type { Company } from "../models/Company";
import type { CompanyCreateRequest } from "../models/CompanyCreateRequest";
import type { CompanyList } from "../models/CompanyList";
import type { CompanyRequest } from "../models/CompanyRequest";
import type { CompanyRetrieve } from "../models/CompanyRetrieve";
import { Conversion } from "../models/Conversion";
import type { CrmUserList } from "../models/CrmUserList";
import type { CustomUser } from "../models/CustomUser";
import type { CustomUserCreateRequest } from "../models/CustomUserCreateRequest";
import type { CustomUserRequest } from "../models/CustomUserRequest";
import type { CustomUserUpdateRequest } from "../models/CustomUserUpdateRequest";
import type { Decomposition } from "../models/Decomposition";
import type { Column, DecompositionByCompany } from "../models/DecompositionByCompany";
import type { DecompositionRequest } from "../models/DecompositionRequest";
import { DecompositionUpdate } from "../models/DecompositionUpdate";
import { DecompositionUpdateRequest } from "../models/DecompositionUpdateRequest";
import type { DepartmentCompanyList } from "../models/DepartmentCompanyList";
import { Email } from "../models/Email";
import { EmailRequest } from "../models/EmailRequest";
import type { EmployeeList } from "../models/EmployeeList";
import type { FinishROPRequest } from "../models/FinishROPRequest";
import type { GetDepartmentsWithEmployeesDepartment } from "../models/GetDepartmentsWithEmployeesDepartment";
import type { ManagerSettings } from "../models/ManagerSettings";
import type { ManagerSettingsCreateRequest } from "../models/ManagerSettingsCreateRequest";
import type { ManagerSettingsUpdateRequest } from "../models/ManagerSettingsUpdateRequest";
import type { MyTokenObtainPair } from "../models/MyTokenObtainPair";
import type { MyTokenObtainPairRequest } from "../models/MyTokenObtainPairRequest";
import type { PackagePermission } from "../models/PackagePermission";
import type { PackagePermissionRequest } from "../models/PackagePermissionRequest";
import type { PackagePermissionUpdateRequest } from "../models/PackagePermissionUpdateRequest";
import { PasswordToken } from "../models/PasswordToken";
import { PasswordTokenRequest } from "../models/PasswordTokenRequest";
import type { PatchedBlockRequest } from "../models/PatchedBlockRequest";
import type { PatchedCompanyRequest } from "../models/PatchedCompanyRequest";
import { PatchedCriteriaRequest } from "../models/PatchedCriteriaRequest";
import type { PatchedCustomUserRequest } from "../models/PatchedCustomUserRequest";
import type { PatchedDecompositionRequest } from "../models/PatchedDecompositionRequest";
import type { PatchedManagerSettingsRequest } from "../models/PatchedManagerSettingsRequest";
import type { PatchedPackagePermissionRequest } from "../models/PatchedPackagePermissionRequest";
import type { PatchedReportRequest } from "../models/PatchedReportRequest";
import { PatchedStatusRequest } from "../models/PatchedStatusRequest";
import { PatchedTasksStatusRequest } from "../models/PatchedTasksStatusRequest";
import { PatchedTrainingRequest } from "../models/PatchedTrainingRequest";
import { Progress } from "../models/Progress";
import { RegisterBlock } from "../models/RegisterBlock";
import { RegisterBlockRequest } from "../models/RegisterBlockRequest";
import { RegisterCriteria } from "../models/RegisterCriteria";
import type { Report } from "../models/Report";
import type { ReportRequest } from "../models/ReportRequest";
import { ResetToken } from "../models/ResetToken";
import { ResetTokenRequest } from "../models/ResetTokenRequest";
import { Status } from "../models/Status";
import { StatusRequest } from "../models/StatusRequest";
import type { StepBackRequest } from "../models/StepBackRequest";
import { TasksStatus } from "../models/TasksStatus";
import { TasksStatusRequest } from "../models/TasksStatusRequest";
import type { TestConnectionRequest } from "../models/TestConnectionRequest";
import type { TokenRefresh } from "../models/TokenRefresh";
import type { TokenRefreshRequest } from "../models/TokenRefreshRequest";
import { Training } from "../models/Training";
import { TrainingCreateRequest } from "../models/TrainingCreateRequest";
import { TrainingRequest } from "../models/TrainingRequest";

export class ApiService {
  /**
   * @param name
   * @returns CompanyList
   * @throws ApiError
   */
  public static apiAccountsCompanyList(name?: string): CancelablePromise<Array<CompanyList>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/accounts/company/",
      query: {
        name,
      },
    });
  }

  /**
   * REGISTER COMPANY STEP 1
   * :param request: Company_model > name, crm_id(CRM_model), step_register)
   * :return Compnay_obj
   * @param requestBody
   * @returns any No response body
   * @throws ApiError
   */
  public static apiAccountsCompanyCreate(
    requestBody: CompanyCreateRequest,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/accounts/company/",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param id A UUID string identifying this company.
   * @returns CompanyRetrieve
   * @throws ApiError
   */
  public static apiAccountsCompanyRetrieve(id: string): CancelablePromise<CompanyRetrieve> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/accounts/company/{id}/",
      path: {
        id,
      },
    });
  }

  /**
   * @param id A UUID string identifying this company.
   * @param requestBody
   * @returns Company
   * @throws ApiError
   */
  public static apiAccountsCompanyUpdate(
    id: string,
    requestBody: CompanyRequest,
  ): CancelablePromise<Company> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/api/accounts/company/{id}/",
      path: {
        id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param id A UUID string identifying this company.
   * @param requestBody
   * @returns Company
   * @throws ApiError
   */
  public static apiAccountsCompanyPartialUpdate(
    id: string,
    requestBody?: PatchedCompanyRequest,
  ): CancelablePromise<Company> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/api/accounts/company/{id}/",
      path: {
        id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param id A UUID string identifying this company.
   * @returns void
   * @throws ApiError
   */
  public static apiAccountsCompanyDestroy(id: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/api/accounts/company/{id}/",
      path: {
        id,
      },
    });
  }

  /**
   * @param requestBody
   * @returns any No response body
   * @throws ApiError
   */
  public static apiAccountsCompanyAddManagersCreate(
    requestBody: AddManagersRequest,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/accounts/company/add_managers/",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param requestBody
   * @returns any No response body
   * @throws ApiError
   */
  public static apiAccountsCompanyAddOwnerCreate(
    requestBody: CustomUserCreateRequest,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/accounts/company/add_owner/",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * :param request: CRM_model > type(UUID), crm_url;
   * @param crmUrl
   * @param type
   * @param requestBody
   * @returns Company
   * @throws ApiError
   */
  public static apiAccountsCompanyCrmCreatorCreate(
    crmUrl: string,
    type: string,
    requestBody: CompanyRequest,
  ): CancelablePromise<Company> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/accounts/company/crm_creator/",
      query: {
        crm_url: crmUrl,
        type,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param requestBody
   * @returns any No response body
   * @throws ApiError
   */
  public static apiAccountsCompanyFinishRopCreate(
    requestBody: FinishROPRequest,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/accounts/company/finish_ROP/",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @returns CompanyRetrieve
   * @throws ApiError
   */
  public static apiAccountsCompanyGetCompanyByUserRetrieve(): CancelablePromise<CompanyRetrieve> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/accounts/company/get_company_by_user/",
    });
  }

  /**
   * @param companyId
   * @param name
   * @returns DepartmentCompanyList
   * @throws ApiError
   */
  public static apiAccountsCompanyGetCompanyDepartmentsList(
    companyId: string,
    name?: string,
  ): CancelablePromise<Array<DepartmentCompanyList>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/accounts/company/get_company_departments/",
      query: {
        company_id: companyId,
        name,
      },
    });
  }

  /**
   * @param companyId
   * @param name
   * @returns EmployeeList
   * @throws ApiError
   */
  public static apiAccountsCompanyGetCompanyEmployeesList(
    companyId: string,
    name?: string,
  ): CancelablePromise<Array<EmployeeList>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/accounts/company/get_company_employees/",
      query: {
        company_id: companyId,
        name,
      },
    });
  }

  /**
   * @param name
   * @returns GetDepartmentsWithEmployeesDepartment
   * @throws ApiError
   */
  public static apiAccountsCompanyGetDepartmentsWithEmployeesList(
    name?: string,
  ): CancelablePromise<Array<GetDepartmentsWithEmployeesDepartment>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/accounts/company/get_departments_with_employees/",
      query: {
        name,
      },
    });
  }

  /**
   * @param requestBody
   * @returns any No response body
   * @throws ApiError
   */
  public static apiAccountsCompanyStepBackUpdate(
    requestBody: StepBackRequest,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/api/accounts/company/step_back/",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param requestBody
   * @returns any No response body
   * @throws ApiError
   */
  public static apiAccountsCompanyTestCrmConnectionCreate(
    requestBody: TestConnectionRequest,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/accounts/company/test_crm_connection/",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param requestBody
   * @returns any No response body
   * @throws ApiError
   */
  public static apiAccountsCompanyUpdatePackagePermissionCreate(
    requestBody: PackagePermissionUpdateRequest,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/accounts/company/update_package_permission/",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Takes a set of user credentials and returns an access and refresh JSON web
   * token pair to prove the authentication of those credentials.
   * @param requestBody
   * @returns MyTokenObtainPair
   * @throws ApiError
   */
  public static apiAccountsLoginCreate(
    requestBody: MyTokenObtainPairRequest,
  ): CancelablePromise<MyTokenObtainPair> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/accounts/login/",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @returns ManagerSettings
   * @throws ApiError
   */
  public static apiAccountsManagerSettingList(): CancelablePromise<Array<ManagerSettings>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/accounts/manager_setting/",
    });
  }

  /**
   * @param formData
   * @returns any No response body
   * @throws ApiError
   */
  public static apiAccountsManagerSettingCreate(
    formData: ManagerSettingsCreateRequest,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/accounts/manager_setting/",
      formData,
      mediaType: "multipart/form-data",
    });
  }

  /**
   * @param id A UUID string identifying this manager settings.
   * @returns ManagerSettings
   * @throws ApiError
   */
  public static apiAccountsManagerSettingRetrieve(id: string): CancelablePromise<ManagerSettings> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/accounts/manager_setting/{id}/",
      path: {
        id,
      },
    });
  }

  /**
   * @param id A UUID string identifying this manager settings.
   * @param formData
   * @returns any No response body
   * @throws ApiError
   */
  public static apiAccountsManagerSettingUpdate(
    id: string,
    formData?: ManagerSettingsUpdateRequest,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/api/accounts/manager_setting/{id}/",
      path: {
        id,
      },
      formData,
      mediaType: "multipart/form-data",
    });
  }

  /**
   * @param id A UUID string identifying this manager settings.
   * @param formData
   * @returns ManagerSettings
   * @throws ApiError
   */
  public static apiAccountsManagerSettingPartialUpdate(
    id: string,
    formData?: PatchedManagerSettingsRequest,
  ): CancelablePromise<ManagerSettings> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/api/accounts/manager_setting/{id}/",
      path: {
        id,
      },
      formData,
      mediaType: "multipart/form-data",
    });
  }

  /**
   * @param id A UUID string identifying this manager settings.
   * @returns void
   * @throws ApiError
   */
  public static apiAccountsManagerSettingDestroy(id: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/api/accounts/manager_setting/{id}/",
      path: {
        id,
      },
    });
  }

  /**
   * @param id A UUID string identifying this manager settings.
   * @param templateType
   * @returns ManagerSettings
   * @throws ApiError
   */
  public static apiAccountsManagerSettingDownloadTemplateRetrieve(
    id: string,
    templateType: string,
  ): CancelablePromise<ManagerSettings> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/accounts/manager_setting/{id}/download_template/",
      path: {
        id,
      },
      query: {
        template_type: templateType,
      },
    });
  }

  /**
   * @param id A UUID string identifying this manager settings.
   * @returns ManagerSettings
   * @throws ApiError
   */
  public static apiAccountsManagerSettingGetBlocksRetrieve(
    id: string,
  ): CancelablePromise<ManagerSettings> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/accounts/manager_setting/{id}/get_blocks/",
      path: {
        id,
      },
    });
  }

  /**
   * @returns PackagePermission
   * @throws ApiError
   */
  public static apiAccountsPackagePermissionList(): CancelablePromise<Array<PackagePermission>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/accounts/package_permission/",
    });
  }

  /**
   * @param requestBody
   * @returns PackagePermission
   * @throws ApiError
   */
  public static apiAccountsPackagePermissionCreate(
    requestBody: PackagePermissionRequest,
  ): CancelablePromise<PackagePermission> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/accounts/package_permission/",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param id A UUID string identifying this package permission.
   * @returns PackagePermission
   * @throws ApiError
   */
  public static apiAccountsPackagePermissionRetrieve(
    id: string,
  ): CancelablePromise<PackagePermission> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/accounts/package_permission/{id}/",
      path: {
        id,
      },
    });
  }

  /**
   * @param id A UUID string identifying this package permission.
   * @param requestBody
   * @returns PackagePermission
   * @throws ApiError
   */
  public static apiAccountsPackagePermissionUpdate(
    id: string,
    requestBody: PackagePermissionRequest,
  ): CancelablePromise<PackagePermission> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/api/accounts/package_permission/{id}/",
      path: {
        id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param id A UUID string identifying this package permission.
   * @param requestBody
   * @returns PackagePermission
   * @throws ApiError
   */
  public static apiAccountsPackagePermissionPartialUpdate(
    id: string,
    requestBody?: PatchedPackagePermissionRequest,
  ): CancelablePromise<PackagePermission> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/api/accounts/package_permission/{id}/",
      path: {
        id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param id A UUID string identifying this package permission.
   * @returns void
   * @throws ApiError
   */
  public static apiAccountsPackagePermissionDestroy(id: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/api/accounts/package_permission/{id}/",
      path: {
        id,
      },
    });
  }

  /**
   * Takes a refresh type JSON web token and returns an access type JSON web
   * token if the refresh token is valid.
   * @param requestBody
   * @returns TokenRefresh
   * @throws ApiError
   */
  public static apiAccountsRefreshTokenCreate(
    requestBody: TokenRefreshRequest,
  ): CancelablePromise<TokenRefresh> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/accounts/refresh_token/",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param companyId
   * @param departmentId
   * @param fullName
   * @param role * `Administrator` - Administrator
   * * `Owner` - Owner
   * * `ROP` - ROP
   * * `Manager` - Manager
   * @returns CustomUser
   * @throws ApiError
   */
  public static apiAccountsUserList(
    companyId?: number,
    departmentId?: number,
    fullName?: string,
    role?: "Administrator" | "Manager" | "Owner" | "ROP",
  ): CancelablePromise<Array<CustomUser>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/accounts/user/",
      query: {
        company_id: companyId,
        department_id: departmentId,
        full_name: fullName,
        role,
      },
    });
  }

  /**
   * :params request: flags(is_owner, is_rop, is_manager)
   * requirement for creating parameters(email, phone, dob, company(id), first_name, last_name, country)
   * if is_owner ->  set group id=2
   * if is_rop -> add parameters(boss(owner_id)) set group id=3
   * if is_manager -> add parameters(boss(owner_id or rop_id), crm_id, manager_setting(id)) set group id=4
   * @param requestBody
   * @returns CustomUser
   * @throws ApiError
   */
  public static apiAccountsUserCreate(
    requestBody: CustomUserRequest,
  ): CancelablePromise<CustomUser> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/accounts/user/",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param id A UUID string identifying this user.
   * @returns CustomUser
   * @throws ApiError
   */
  public static apiAccountsUserRetrieve(id: string): CancelablePromise<CustomUser> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/accounts/user/{id}/",
      path: {
        id,
      },
    });
  }

  /**
   * @param id A UUID string identifying this user.
   * @param requestBody
   * @returns CustomUser
   * @throws ApiError
   */
  public static apiAccountsUserUpdate(
    id: string,
    requestBody: CustomUserRequest,
  ): CancelablePromise<CustomUser> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/api/accounts/user/{id}/",
      path: {
        id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param id A UUID string identifying this user.
   * @param requestBody
   * @returns CustomUser
   * @throws ApiError
   */
  public static apiAccountsUserPartialUpdate(
    id: string,
    requestBody?: PatchedCustomUserRequest,
  ): CancelablePromise<CustomUser> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/api/accounts/user/{id}/",
      path: {
        id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param id A UUID string identifying this user.
   * @returns void
   * @throws ApiError
   */
  public static apiAccountsUserDestroy(id: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/api/accounts/user/{id}/",
      path: {
        id,
      },
    });
  }

  /**
   * @param requestBody
   * @returns any No response body
   * @throws ApiError
   */
  public static apiAccountsUserCreateUserCreate(
    requestBody: CustomUserCreateRequest,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/accounts/user/create_user/",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param requestBody
   * @returns any No response body
   * @throws ApiError
   */
  public static apiAccountsUserUpdateUserCreate(
    requestBody: CustomUserUpdateRequest,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/accounts/user/update_user/",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static apiMainDefaultPrompt(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/main/default_prompt/",
    });
  }

  /**
   * @returns BlockType
   * @throws ApiError
   */
  public static apiMainBlockType(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/main/block_type/",
    });
  }

  /**
   * @returns BlockList
   * @throws ApiError
   */
  public static apiMainBlockList(): CancelablePromise<Array<BlockList>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/main/block/",
    });
  }

  /**
   * @param requestBody
   * @returns Block
   * @throws ApiError
   */
  public static apiMainBlockCreate(requestBody: BlockRequest): CancelablePromise<Block> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/main/block/",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param id A UUID string identifying this block.
   * @returns Block
   * @throws ApiError
   */
  public static apiMainBlockRetrieve(id: string): CancelablePromise<Block> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/main/block/{id}/",
      path: {
        id,
      },
    });
  }

  /**
   * @param id A UUID string identifying this block.
   * @param requestBody
   * @returns Block
   * @throws ApiError
   */
  public static apiMainBlockUpdate(
    id: string,
    requestBody: BlockRequest,
  ): CancelablePromise<Block> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/api/main/block/{id}/",
      path: {
        id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param id A UUID string identifying this block.
   * @param requestBody
   * @returns Block
   * @throws ApiError
   */
  public static apiMainBlockPartialUpdate(
    id: number,
    requestBody?: PatchedBlockRequest,
  ): CancelablePromise<Block> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/api/main/block/{id}/",
      path: {
        id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param id A UUID string identifying this block.
   * @returns void
   * @throws ApiError
   */
  public static apiMainBlockDestroy(id: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/api/main/block/{id}/",
      path: {
        id,
      },
    });
  }

  /**
   * @returns RegisterBlock
   * @throws ApiError
   */
  public static apiMainRegisterBlockList(): CancelablePromise<Array<RegisterBlock>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/main/register-blocks/",
    });
  }

  /**
   * @returns RegisterBlock
   * @throws ApiError
   */
  public static apiMainRegisterBlockId(id: number): CancelablePromise<RegisterBlock> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/main/register-blocks/{id}",
      path: {
        id,
      },
    });
  }

  /**
   * @param requestBody
   * @returns RegisterBlock
   * @throws ApiError
   */
  public static apiMainCreateRegisterBlockList(
    requestBody: RegisterBlockRequest,
  ): CancelablePromise<RegisterBlock> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/main/register-blocks/",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param id A UUID string identifying this block.
   * @param requestBody
   * @returns RegisterBlock
   * @throws ApiError
   */
  public static apiMainEditRegisterBlockList(
    id: string,
    requestBody: RegisterBlock,
  ): CancelablePromise<RegisterBlock> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/api/main/register-blocks/{id}/",
      path: {
        id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param id number
   * @returns void
   * @throws ApiError
   */
  public static apiMainDeleteRegisterBlockList(id: number): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/api/main/register-blocks/{id}/",
      path: {
        id,
      },
    });
  }

  /**
   * @returns RegisterCriteria
   * @throws ApiError
   */
  public static apiMainRegisterCriteriaList(): CancelablePromise<Array<RegisterCriteria>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/main/register-criteria/",
    });
  }

  /**
   * @param requestBody
   * @returns RegisterCriteria
   * @throws ApiError
   */
  public static apiMainCreateCriteriaList(
    requestBody: PatchedCriteriaRequest,
  ): CancelablePromise<RegisterCriteria> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/main/register-criteria/",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param id number
   * @param requestBody
   * @returns RegisterCriteria
   * @throws ApiError
   */
  public static apiMainEditCriteriaList(
    id: number,
    requestBody?: PatchedCriteriaRequest,
  ): CancelablePromise<RegisterCriteria> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/api/main/register-criteria/{id}/",
      path: {
        id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param id number
   * @param requestBody
   * @returns RegisterCriteria
   * @throws ApiError
   */
  public static apiMainRegisterCriteriaPartialUpdate(
    id: number,
    requestBody?: PatchedCriteriaRequest,
  ): CancelablePromise<RegisterCriteria> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/api/main/register-criteria/{id}/",
      path: {
        id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param id number
   * @returns void
   * @throws ApiError
   */
  public static apiMainDeleteCriteriaList(id: number): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/api/main/register-criteria/{id}/",
      path: {
        id,
      },
    });
  }

  /**
   * Получение названий воронок для подвязывания к таблице РНП
   * @param companyId
   * @returns any No response body
   * @throws ApiError
   */
  public static apiMainCrmPipelineRetrieve(companyId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/main/crm_pipeline/{company_id}",
      path: {
        company_id: companyId,
      },
    });
  }

  /**
   * @param companyId
   * @param query
   * @returns CrmUserList
   * @throws ApiError
   */
  public static apiMainGetCrmUserList(
    companyId: string,
    query?: string,
  ): CancelablePromise<Array<CrmUserList>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/main/get_crm_user/{company_id}",
      path: {
        company_id: companyId,
      },
      query: {
        query,
      },
    });
  }

  /**
   * Получение статусов воронок для подвязывания к таблице РНП
   * @param companyId
   * @param query
   * @returns ChosePipline
   * @throws ApiError
   */
  public static apiMainPipelineStatusList(
    companyId: string,
    query?: string,
  ): CancelablePromise<Array<ChosePipline>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/main/pipeline_status/{company_id}",
      path: {
        company_id: companyId,
      },
      query: {
        query,
      },
    });
  }

  /**
   * @returns Report
   * @throws ApiError
   */
  public static apiMainReportsList(): CancelablePromise<Array<Report>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/main/reports/",
    });
  }

  /**
   * @param requestBody
   * @returns Report
   * @throws ApiError
   */
  public static apiMainReportsCreate(requestBody: ReportRequest): CancelablePromise<Report> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/main/reports/",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param id A UUID string identifying this report day.
   * @returns Report
   * @throws ApiError
   */
  public static apiMainReportsRetrieve(id: string): CancelablePromise<Report> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/main/reports/{id}/",
      path: {
        id,
      },
    });
  }

  /**
   * @param id A UUID string identifying this report day.
   * @param requestBody
   * @returns Report
   * @throws ApiError
   */
  public static apiMainReportsUpdate(
    id: string,
    requestBody: ReportRequest,
  ): CancelablePromise<Report> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/api/main/reports/{id}/",
      path: {
        id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param id A UUID string identifying this report day.
   * @param requestBody
   * @returns Report
   * @throws ApiError
   */
  public static apiMainReportsPartialUpdate(
    id: string,
    requestBody?: PatchedReportRequest,
  ): CancelablePromise<Report> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/api/main/reports/{id}/",
      path: {
        id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param id A UUID string identifying this report day.
   * @returns void
   * @throws ApiError
   */
  public static apiMainReportsDestroy(id: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/api/main/reports/{id}/",
      path: {
        id,
      },
    });
  }

  /**
   * @returns Report
   * @throws ApiError
   */
  public static apiMainReportsGenerateExcelReportRetrieve(): CancelablePromise<Report> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/main/reports/generate_excel_report/",
    });
  }

  /**
   * @param createdAt
   * @param departmentId
   * @param managerId
   * @param typeReport
   * @returns Report
   * @throws ApiError
   */
  public static apiMainReportsGetReportRetrieve(
    departmentId?: string,
    managerId?: string,
    typeReport = "Day",
    createdAt = "Wed Aug 07 2024 17:53:52 GMT+0500 (Западный Казахстан)",
  ): CancelablePromise<Report> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/main/reports/get_report/",
      query: {
        created_at: createdAt,
        department_id: departmentId,
        manager_id: managerId,
        type_report: typeReport,
      },
    });
  }

  /**
   * @returns Decomposition
   * @throws ApiError
   */
  public static apiMainRnpList(): CancelablePromise<Array<Decomposition>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/main/rnp/",
    });
  }

  /**
   * @param requestBody
   * @returns Decomposition
   * @throws ApiError
   */
  public static apiMainRnpCreate(
    requestBody: DecompositionRequest,
  ): CancelablePromise<Decomposition> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/main/rnp/",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param id A UUID string identifying this decomposition.
   * @returns Decomposition
   * @throws ApiError
   */
  public static apiMainRnpRetrieve(id: string): CancelablePromise<Decomposition> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/main/rnp/{id}/",
      path: {
        id,
      },
    });
  }

  /**
   * @param id A UUID string identifying this decomposition.
   * @param requestBody
   * @returns Decomposition
   * @throws ApiError
   */
  public static apiMainRnpUpdate(
    id: string,
    requestBody: DecompositionRequest,
  ): CancelablePromise<Decomposition> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/api/main/rnp/{id}/",
      path: {
        id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param id A UUID string identifying this decomposition.
   * @param requestBody
   * @returns Decomposition
   * @throws ApiError
   */
  public static apiMainRnpPartialUpdate(
    id: string,
    requestBody?: PatchedDecompositionRequest,
  ): CancelablePromise<Array<Column>> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/api/main/rnp/{id}/",
      path: {
        id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param id A UUID string identifying this decomposition.
   * @returns void
   * @throws ApiError
   */
  public static apiMainRnpDestroy(id: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/api/main/rnp/{id}/",
      path: {
        id,
      },
    });
  }

  /**
   * @param companyId
   * @param departmentId
   * @param managerId
   * @returns DecompositionByCompany
   * @throws ApiError
   */
  public static apiMainRnpGetDecompositionsByCompanyCreate(
    companyId: string,
    departmentId?: string,
    managerId?: string,
  ): CancelablePromise<Array<DecompositionByCompany>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/main/rnp/filter_rnp/",
      query: {
        company_id: companyId,
        department_id: departmentId,
        manager_id: managerId,
      },
    });
  }

  /**
   * @param companyId
   * @param departmentId
   * @param managerId
   * @returns Conversion
   * @throws ApiError
   */
  public static apiMainRnpGetConversionsByCompanyCreate(
    companyId: string,
    departmentId?: string,
    managerId?: string,
  ): CancelablePromise<Array<Conversion>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/main/conversion/filter_conversions/",
      query: {
        company_id: companyId,
        department_id: departmentId,
        manager_id: managerId,
      },
    });
  }

  /**
   * @param companyId
   * @param departmentId
   * @param managerId
   * @returns Progress
   * @throws ApiError
   */
  public static apiMainRnpGetProgressByCompanyCreate(
    companyId: string,
    departmentId?: string,
    managerId?: string,
  ): CancelablePromise<Array<Progress>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/main/progress/filter_process/",
      query: {
        company_id: companyId,
        department_id: departmentId,
        manager_id: managerId,
      },
    });
  }

  /**
   * @param date
   * @param departmentId
   * @param managerId
   * @returns Decomposition
   * @throws ApiError
   */
  public static apiMainRnpGetDecompositionsForRopRetrieve(
    date: string,
    departmentId?: string,
    managerId?: string,
  ): CancelablePromise<Decomposition> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/main/rnp/get_decompositions_for_rop/",
      query: {
        date,
        department_id: departmentId,
        manager_id: managerId,
      },
    });
  }

  /**
   * @param requestBody
   * @returns DecompositionUpdate
   * @throws ApiError
   */
  public static apiMainRnpUpdateRnpValueCreate(
    requestBody: DecompositionUpdateRequest,
  ): CancelablePromise<DecompositionUpdate> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/main/rnp/update_rnp_value/",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @returns Status
   * @throws ApiError
   */
  public static apiMainStatusesList(): CancelablePromise<Array<Status>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/main/statuses/",
    });
  }

  /**
   * @param requestBody
   * @returns Status
   * @throws ApiError
   */
  public static apiMainStatusesCreate(requestBody: StatusRequest): CancelablePromise<Status> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/main/statuses/",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param id A UUID string identifying this status time count.
   * @returns Status
   * @throws ApiError
   */
  public static apiMainStatusesRetrieve(id: string): CancelablePromise<Status> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/main/statuses/{id}/",
      path: {
        id,
      },
    });
  }

  /**
   * @param id A UUID string identifying this status time count.
   * @param requestBody
   * @returns Status
   * @throws ApiError
   */
  public static apiMainStatusesUpdate(
    id: string,
    requestBody: StatusRequest,
  ): CancelablePromise<Status> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/api/main/statuses/{id}/",
      path: {
        id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param id A UUID string identifying this status time count.
   * @param requestBody
   * @returns Status
   * @throws ApiError
   */
  public static apiMainStatusesPartialUpdate(
    id: string,
    requestBody?: PatchedStatusRequest,
  ): CancelablePromise<Status> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/api/main/statuses/{id}/",
      path: {
        id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param id A UUID string identifying this status time count.
   * @returns void
   * @throws ApiError
   */
  public static apiMainStatusesDestroy(id: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/api/main/statuses/{id}/",
      path: {
        id,
      },
    });
  }

  /**
   * @param date
   * @param departmentId
   * @param managerId
   * @returns Status
   * @throws ApiError
   */
  public static apiMainStatusesGetStatusesForRopRetrieve(
    companyId: string,
    date: string,
    departmentId?: string,
    managerId?: string,
  ): CancelablePromise<Status> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/main/statuses/filter_statuses/",
      query: {
        company_id: companyId,
        date,
        department_id: departmentId,
        manager_id: managerId,
      },
    });
  }

  /**
   * @returns TasksStatus
   * @throws ApiError
   */
  public static apiMainTasksList(): CancelablePromise<Array<TasksStatus>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/main/tasks/",
    });
  }

  /**
   * @param requestBody
   * @returns TasksStatus
   * @throws ApiError
   */
  public static apiMainTasksCreate(
    requestBody: TasksStatusRequest,
  ): CancelablePromise<TasksStatus> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/main/tasks/",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param id A UUID string identifying this tasks time count.
   * @returns TasksStatus
   * @throws ApiError
   */
  public static apiMainTasksRetrieve(id: string): CancelablePromise<TasksStatus> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/main/tasks/{id}/",
      path: {
        id,
      },
    });
  }

  /**
   * @param id A UUID string identifying this tasks time count.
   * @param requestBody
   * @returns TasksStatus
   * @throws ApiError
   */
  public static apiMainTasksUpdate(
    id: string,
    requestBody: TasksStatusRequest,
  ): CancelablePromise<TasksStatus> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/api/main/tasks/{id}/",
      path: {
        id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param id A UUID string identifying this tasks time count.
   * @param requestBody
   * @returns TasksStatus
   * @throws ApiError
   */
  public static apiMainTasksPartialUpdate(
    id: string,
    requestBody?: PatchedTasksStatusRequest,
  ): CancelablePromise<TasksStatus> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/api/main/tasks/{id}/",
      path: {
        id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param id A UUID string identifying this tasks time count.
   * @returns void
   * @throws ApiError
   */
  public static apiMainTasksDestroy(id: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/api/main/tasks/{id}/",
      path: {
        id,
      },
    });
  }

  /**
   * @param date
   * @param departmentId
   * @param managerId
   * @returns TasksStatus
   * @throws ApiError
   */
  public static apiMainTasksGetTasksForRopRetrieve(
    companyId: string,
    date: string,
    departmentId?: string,
    managerId?: string,
  ): CancelablePromise<TasksStatus> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/main/tasks/filter_tasks/",
      query: {
        company_id: companyId,
        date,
        department_id: departmentId,
        manager_id: managerId,
      },
    });
  }

  /**
   * @returns Training
   * @throws ApiError
   */
  public static apiMainTrainingList(): CancelablePromise<Array<Training>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/main/training/",
    });
  }

  /**
   * @param formData
   * @returns any No response body
   * @throws ApiError
   */
  public static apiMainTrainingCreate(formData: TrainingCreateRequest): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/main/training/",
      formData,
      mediaType: "multipart/form-data",
    });
  }

  /**
   * @param id A UUID string identifying this training.
   * @returns Training
   * @throws ApiError
   */
  public static apiMainTrainingRetrieve(id: string): CancelablePromise<Training> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/main/training/{id}/",
      path: {
        id,
      },
    });
  }

  /**
   * @param id A UUID string identifying this training.
   * @param formData
   * @returns Training
   * @throws ApiError
   */
  public static apiMainTrainingUpdate(
    id: string,
    formData: TrainingRequest,
  ): CancelablePromise<Training> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/api/main/training/{id}/",
      path: {
        id,
      },
      formData,
      mediaType: "multipart/form-data",
    });
  }

  /**
   * @param id A UUID string identifying this training.
   * @param formData
   * @returns Training
   * @throws ApiError
   */
  public static apiMainTrainingPartialUpdate(
    id: string,
    formData?: PatchedTrainingRequest,
  ): CancelablePromise<Training> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/api/main/training/{id}/",
      path: {
        id,
      },
      formData,
      mediaType: "multipart/form-data",
    });
  }

  /**
   * @param id A UUID string identifying this training.
   * @returns void
   * @throws ApiError
   */
  public static apiMainTrainingDestroy(id: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/api/main/training/{id}/",
      path: {
        id,
      },
    });
  }

  /**
   * ТЕСТОВАЯ ФУНКЦИЯ ДЛЯ СЕЛЕРИ
   * @param companyId
   * @param query
   * @returns ChosePipline
   * @throws ApiError
   */
  public static apiMainTransportStatusRetrieve(
    companyId: string,
    query?: string,
  ): CancelablePromise<ChosePipline> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/main/transport_status/{company_id}",
      path: {
        company_id: companyId,
      },
      query: {
        query,
      },
    });
  }

  /**
   * An Api View which provides a method to request a password reset token based on an e-mail address
   *
   * Sends a signal reset_password_token_created when a reset token was created
   * @param requestBody
   * @returns Email
   * @throws ApiError
   */
  public static apiPasswordResetCreate(requestBody: EmailRequest): CancelablePromise<Email> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/password_reset/",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * An Api View which provides a method to reset a password based on a unique token
   * @param requestBody
   * @returns PasswordToken
   * @throws ApiError
   */
  public static apiPasswordResetConfirmCreate(
    requestBody: PasswordTokenRequest,
  ): CancelablePromise<PasswordToken> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/password_reset/confirm/",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * An Api View which provides a method to verify that a token is valid
   * @param requestBody
   * @returns ResetToken
   * @throws ApiError
   */
  public static apiPasswordResetValidateTokenCreate(
    requestBody: ResetTokenRequest,
  ): CancelablePromise<ResetToken> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/password_reset/validate_token/",
      body: requestBody,
      mediaType: "application/json",
    });
  }
}
