/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Input } from "antd";
import React, { useState } from "react";
import styles from "../../css/Company/ExpandableText.module.scss"; // Импортируем SCSS-модуль

const { TextArea } = Input;

const ExpandableText: React.FC<{ text: string }> = ({ text }) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpand = () => setExpanded(!expanded);

  return (
    <div className={styles.expandableText}>
      <TextArea
        value={expanded ? text : `${text.slice(0, 300)}${text.length > 300 ? "..." : ""}`}
        readOnly
        autoSize={{ minRows: 4 }}
        className={`${styles.textArea} ${expanded ? styles.expanded : styles.collapsed}`}
      />
      {text.length > 300 && (
        <a onClick={toggleExpand} className={styles.toggleLink}>
          {expanded ? "Свернуть" : "Показать полностью"}
        </a>
      )}
    </div>
  );
};

export default ExpandableText;
